<template>
  <v-row wrap>
    <v-col cols="8" class="full-screen-height">
      <v-col cols="12">
        <UserUiToolbar
          v-model="searchUser"
          v-on:refresh="refresh"
          v-on:open-new-user="openNewUser"
          v-on:open-role-users="openRoleUsers"
          v-on:open-role-by-type="openRoleByType"
          v-on:open-right-by-role="openRightByRole"
        >
        </UserUiToolbar>
        <v-divider></v-divider>
        <v-dialog eager v-model="newUserDialog">
          <UserUiCard
            v-model="newUser"
            v-on:create="createNewUser"
            v-on:close="closeUserDialog"
            :is-processing="isProcessingNewOrUpdateUser"
          />
        </v-dialog>

        <v-dialog eager v-model="dialogUpdateStatus">
          <UserUiCard
            v-model="updatedUser"
            v-on:create="processUpdateStatus"
            v-on:close="closeUpdateUserDialog"
            :is-update-dialog="true"
            :selected-id="selectedId"
            :is-processing="isProcessingNewOrUpdateUser"
          />
        </v-dialog>

      </v-col>
      <v-col cols="12" class="user-table-container">
        <UserUiTable
          :search-user="searchUser"
          :users="users"
          v-model="selected"
          :selected-id="selectedId"
          v-on:edit-user="editUser"
          v-on:delete-user="askDeleteUser"
          :has-user-right-to-delete="haveCurrentUserTheRole('api.user:delete')"
          :has-user-right-to-edit="haveCurrentUserTheRole('api.user:put')"
        >
        </UserUiTable>
      </v-col>
    </v-col>

    <v-col cols="4">
      <UserUiPanel
        v-model="rightsToAdd"
        :selected-id="selectedId"
        :current-user-roles="currentUserRoles"
        :is-bucket-edit="isBucketEdit"
        :current-roles="currentRoles"
        :is-loading-role="isLoadingRole"
        :selected="selected"
        :is-processing-add="isProcessingAdd"
        :is-processing-delete="isProcessingDelete"
        v-on:add-rights="processAddRights"
        v-on:open-update-status="openUpdateStatus"
        v-on:delete-rights="processDeleteRights"
        :updated-user="getUpdatedUser()"
      >
      </UserUiPanel>
    </v-col>

    <v-dialog eager v-model="dialogRoleUsers" fullscreen>
      <RoleUsers
        v-on:close="closeRoleUsers"
        v-on:delete-rights="processDeleteRights"
      >

      </RoleUsers>
    </v-dialog>

    <v-dialog eager v-model="dialogRoleByType" fullscreen>
      <RoleByType
        class="no-display"
        v-on:close="closeRoleByType"
      >
      </RoleByType>
    </v-dialog>

    <v-dialog eager v-model="dialogRightByRole" fullscreen>
      <UserRightByRole
        v-on:close="closeRightByRole"
      >
      </UserRightByRole>
    </v-dialog>

    <SimplePrompt
      :dialog="dialogDelete"
      :text="`Are you sure you want to delete the user with id ${userToDelete} ?`"
      @approve="deleteUser(userToDelete)"
      @cancel="cancelDelete"
      approve="Delete"
      cancel="Conserve"
      :loading="loadingDeleteUser"
    >

    </SimplePrompt>

  </v-row>
</template>

<script>
import { usersMixin } from '../mixins/usersMixin'
import UserModel from '../models/Users/UserModel'
import UserUiTable from '../components/UserUiComponents/UserUiTable'
import UserUiCard from '../components/UserUiComponents/UserUiCard'
import UserUiPanel from '../components/UserUiComponents/UserUiPanel'
import UserUiToolbar from '../components/UserUiComponents/UserUiToolbar'
import RoleUsers from '../components/UserUiComponents/RoleUsers'
import RoleByType from '../components/UserUiComponents/RoleByType'
import SimplePrompt from '@/components/Common/SimplePrompt'
import UserRightByRole from '@/components/UserUiComponents/UserRightByRole.vue'
import baseViewMixin from '@/mixins/baseViewMixin'

export default {
  name: 'UserUi.vue',
  components: { UserRightByRole, SimplePrompt, RoleByType, RoleUsers, UserUiCard, UserUiTable, UserUiPanel, UserUiToolbar },
  mixins: [usersMixin, baseViewMixin],
  data: function () {
    return {
      selected: [],
      pagination: {
        sortBy: 'userId'
      },
      isLoadingRole: false,
      selectedId: null,
      currentRoles: [],
      rightsToAdd: [],
      searchUser: '',
      newUserDialog: false,
      newUser: new UserModel({}),
      dialogUpdateStatus: false,
      updatedUser: new UserModel({}),
      dialogRoleUsers: false,
      dialogRoleByType: false,
      dialogRightByRole: false,
      isProcessingAdd: false,
      isProcessingDelete: false,
      isProcessingNewOrUpdateUser: false,
      dialogDelete: false,
      userToDelete: null,
      loadingDeleteUser: false
    }
  },
  mounted () {
    this.onMounted('hidden')
    this.refresh()
  },
  methods: {
    async editUser (userId) {
      this.selected = []
      this.selectedId = userId
      this.isLoadingRole = true
      let roles = await this.getUserRoles(userId)
      this.currentRoles = roles[0].map(item => { return { 'role': item } })
      this.isLoadingRole = false
    },
    async deleteUser (userId) {
      if (!userId) {
        console.warn('No user id to delete.')
        return
      }
      this.loadingDeleteUser = true
      const result = await this.$apiCaller.deleteUser(userId)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when deleting the user')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        const message = 'Delete success !'
        this.$emit('ask-snackbar', message)
      }
      this.loadingDeleteUser = false
      this.resetValuesUserDelete()
      await this.refresh()
    },
    resetValuesUserDelete () {
      this.dialogDelete = false
      this.userToDelete = null
    },
    askDeleteUser (userId) {
      this.userToDelete = userId
      this.dialogDelete = true
    },
    cancelDelete () {
      this.resetValuesUserDelete()
    },
    async getUserRoles (userId) {
      const result = await this.$apiCaller.getUserRoles(userId)
      let roles = []
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling api')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        roles = result.data
      }
      return roles
    },
    async processAddRights () {
      this.isProcessingAdd = true
      const toAdd = this.rightsToAdd
      let userIds = [this.selectedId]
      if (this.isBucketEdit) {
        userIds = this.selected.map(item => item.userId)
      }
      const result = await this.$apiCaller.putUserRoles(userIds, toAdd.join(','))

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling api')
        const message = 'Error on update'
        this.$emit('ask-snackbar', message, 'error')
      } else {
        const message = 'Update success !'
        this.$emit('ask-snackbar', message)
        this.resetAllValues()
      }
      this.isProcessingAdd = false
    },
    async processDeleteRights (toDelete, selectedIds = null) {
      this.isProcessingDelete = true
      let userIds = selectedIds === null ? [this.selectedId] : selectedIds
      const result = await this.$apiCaller.deleteUserRoles(userIds, toDelete.join(','))

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling api')
        const message = 'Error on update'
        this.$emit('ask-snackbar', message, 'error')
      } else {
        const message = 'Delete success !'
        this.$emit('ask-snackbar', message)
        this.resetAllValues()
      }
      this.isProcessingDelete = false
    },
    getUpdatedUser () {
      let userToUpdate = this.users.filter(item => item.userId === this.selectedId)
      if (userToUpdate.length === 0) {
        return null
      }
      return new UserModel({
        isDebugger: userToUpdate[0].isDebugger,
        isAm: userToUpdate[0].isAm,
        isSale: userToUpdate[0].isSale,
        mail: userToUpdate[0].mail,
        isSettupper: userToUpdate[0].isSettupper,
        isAdminAndFinance: userToUpdate[0].isAdminAndFinance,
        user_id: this.selectedId,
        dv_mail: userToUpdate[0].dvMail
      })
    },
    openUpdateStatus () {
      let userToUpdate = this.getUpdatedUser()
      if (userToUpdate === null) {
        return
      }
      this.updatedUser = userToUpdate
      this.dialogUpdateStatus = true
    },
    async processUpdateStatus () {
      this.isProcessingNewOrUpdateUser = true
      if (this.isBucketEdit) {
        return
      }
      const response = await this.$apiCaller.putUser(
        this.selectedId,
        this.updatedUser.isDebugger,
        this.updatedUser.isAm,
        this.updatedUser.isSale,
        this.updatedUser.isSettupper,
        this.updatedUser.isAdminAndFinance,
        this.updatedUser.dvMail
      )

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling api')
        const message = 'Error on update'
        this.$emit('ask-snackbar', message, 'error')
      } else {
        const message = 'Update success !'
        this.$emit('ask-snackbar', message)
      }
      this.closeUpdateUserDialog()
      this.isProcessingNewOrUpdateUser = false
      this.resetAllValues()
      await this.refresh()
    },
    closeUpdateUserDialog () {
      this.dialogUpdateStatus = false
    },
    resetBucketValues () {
      this.selected = []
      this.rightsToAdd = []
    },
    resetSingleUserValues () {
      this.selectedId = null
    },
    resetAllValues () {
      this.resetBucketValues()
      this.resetSingleUserValues()
    },
    async aadDoubleCheck (userMail) {
      // Double check if the mail is in the AAD
      const result = await this.$apiCaller.getAllowedAuthDomains()
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling getAllowedAuthDomains')
        this.$store.commit('setErrorMessageWithResponse', result)
        return false
      } else {
        return this.$commonUtils.endsWithAny(result.data, userMail)
      }
    },
    async createNewUser () {
      let aadResult = await this.aadDoubleCheck(this.newUser.dvMail)
      if (!aadResult) {
        let message = 'Error: user mail is not part of the Allowed Auth Domains'
        console.warn(message)
        this.$emit('ask-snackbar', message, 'error')
        return
      }
      this.isProcessingNewOrUpdateUser = true
      const result = await this.$apiCaller.postUser(this.newUser.dvMail, this.newUser.isDebugger, this.newUser.isAm, this.newUser.isSale, this.newUser.isSettupper, this.newUser.isAdminAndFinance)
      if (this.$apiCaller.isResponseError(result)) {
        let message = 'Error when creating the user'
        console.warn(message)
        this.$emit('ask-snackbar', message, 'error')
      } else {
        let message = 'Success !'
        this.$emit('ask-snackbar', message, 'success')
      }
      this.closeUserDialog()
      this.isProcessingNewOrUpdateUser = false
      await this.refresh()
    },
    closeUserDialog () {
      this.newUserDialog = false
    },
    openNewUser () {
      this.newUserDialog = true
      this.newUser = new UserModel({
        isDebugger: false,
        isAm: false,
        isSale: false,
        isSettupper: false,
        isAdminAndFinance: false,
        mail: '',
        user_id: null
      })
    },
    openRoleUsers () {
      this.dialogRoleUsers = true
    },
    openRoleByType () {
      // this.dialogRoleByType = true
    },
    closeRoleByType () {
      this.dialogRoleByType = false
    },
    openRightByRole () {
      this.dialogRightByRole = true
    },
    closeRightByRole () {
      this.dialogRightByRole = false
    },
    closeRoleUsers () {
      this.dialogRoleUsers = false
    },
    async refresh () {
      let response = await this.$store.dispatch('getUsersData')

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when refresh the user')
        this.$store.commit('setErrorMessageWithResponse', response)
      }
    }
  },
  computed: {
    users () {
      return this.$store.getters.getUsers
    },
    isBucketEdit () {
      return !(this.selected.length === 0)
    },
    currentUserRoles () {
      return this.$store.getters.getCurrentUserRoles
    }
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.isBucketEdit) {
          this.selectedId = null
        }
      }
    }
  }
}
</script>

<style scoped>
  .user-table-container {
    height: 87%;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>

<style>
  .full-screen-height {
    height: 93vh;
  }
</style>
